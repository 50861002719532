<template>
  <div class="page-error">
    <div class="page-content">
      <div class="page-error-image">
        <img src="@/assets/img/svgs/blank_canvas.svg" class="img-error"/><br>
      </div>
      <div class="page-error-text">
        <h4>Page Not Found</h4>
        <p>The page you are looking for not available</p>
        <a href="/" class="btn btn-primary btn-outline-primary text-white">
          <i class="fa fa-arrow-left"></i> Back to Home
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  metaInfo: {
    title: '404',
    titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: '404 not found' }
    ]
  }
}
</script>

<style scoped>
  .page-error {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primary-font);
  }

  .page-content {
    display: block;
    text-align: center;
  }

  img {
    width: 40%;
  }
</style>
